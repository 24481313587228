
  import { defineComponent, ref, watch } from 'vue';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useI18n } from 'vue-i18n/index';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';

  export default defineComponent({
    name: 'factor-values-table',
    components: {
      Datatable,
    },
    emit: ['show-edit-modal'],
    props: {
      value: { type: Object, required: true },
    },
    async setup(props, { emit }) {
      const store = useStore();
      const tableData = ref(props.value.ireFactorsConfig);
      const tableTitle = ref(props.value.name);
      const factorId = ref(props.value.id);
      const { t, te } = useI18n();
      const { can } = useAbility();
      watch(
        () => props.value,
        (changed) => {
          tableData.value = changed.ireFactorsConfig;
        }
      );

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const tableHeader = ref([
        {
          name: translate('factorOption'),
          key: 'ireFactorOption',
          sortable: true,
        },
        {
          name: translate('value'),
          key: 'value',
          sortable: true,
        },

        {
          name: translate('actions'),
          key: 'actions',
          sortable: false,
        },
      ]);

      const deleteOption = async (id) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          text: translate('youCantRevert'),
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirmDelete'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          const index = tableData.value.findIndex((v) => v.id === id);
          await store.dispatch(Actions.DELETE_IRE_FACTOR_VALUES, id);
          tableData.value.splice(index, 1);
        }
      };

      const showEditIreModal = () => {
        emit('show-edit-modal', {
          value: props.value,
          factorId: factorId.value,
          factorTitle: tableTitle.value,
        });
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        factorId,
        translate,
        tableHeader,
        tableTitle,
        tableData,
        can,
        deleteOption,
        showEditIreModal,
      };
    },
  });
